<template>
  <div class="detail">
    <h1>This is an logistic detail page</h1>
  </div>
</template>
<script>
  export default {
    data () {
      return {
      }
    },
    created () {
      console.log(this.$route.params.id)
    },
    methods: {
    
    },
  }
</script>